export const sidebarpage = [

    {
        href: "#home",
        title: "Home"
    },

    {
        href: "#about",
        title: "About"
    },


    {
        href: "#experience",
        title: "Experience"
    },


    {
        href: "#service",
        title: "Service"
    },

    {
        href: "#portfolio",
        title: "Portfolio"
    },

    // {
    //     href: "#news",
    //     title: "News"
    // },

    {
        href: "#contact",
        title: "Contacts"
    },

]