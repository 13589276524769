export const education = [
    // {
    //     date: "June 15, 2013 - 2016",
    //     title: "Master in Computer Engineering",
    //     category: "First Class",
    //     description: ">Lorem Ipsum Commodo Dolor Sit Amet, Consectetur Adipisicing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam"
    // },
    {
        date: "Sept 2023 - 2027",
        title: "Bachelor in Computer Science",
        category: "University of Toronto",
        description: ">Lorem Ipsum Commodo Dolor Sit Amet, Consectetur Adipisicing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam"
    },
    {
        date: "2021 - June 2023",
        title: "High-school Diploma",
        category: "Waterloo Collegiate Institute",
        description: ">Lorem Ipsum Commodo Dolor Sit Amet, Consectetur Adipisicing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam"
    },
    // {
    //     date: "June 1, 2009 - 2010",
    //     title: "Master in Computer Engineering",
    //     category: "(A+)",
    //     description: ">Lorem Ipsum Commodo Dolor Sit Amet, Consectetur Adipisicing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam"
    // },

]