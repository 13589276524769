export const counter_card = [
    {
        title : "Projects",
        number : 10
    },
    {
        title : "Technologies",
        number : 12
    },
    {
        title : "Collaborations",
        number : 5
    },

    {
        title : "Awards",
        number : 3
    }
]